<template>
  <div>
    <PageHeader :title="pageTitle" :items="breadcrumb" />
    <div style="width: 100%; margin: 10px 0">
      <b-card>
        <h4>Buscar CNPJ</h4>

        <div class="busca-cnpj d-flex">
          <input
            type="text"
            class="form-control search-input"
            v-model="cnpj"
            v-mask="'##.###.###/####-##'"
          />
          <div class="busca-cnpj">
            <button
              class="btn btn-primary"
              style="gap: 50px"
              @click="buscarempresas"
            >
              Buscar Infos Receita
            </button>
          </div>
        </div>
      </b-card>
      <div v-if="erroHttp" class="alert alert-danger mt-3">
        Ocorreu um erro na requisição.
      </div>
      <b-card border-variant="dark">
        <b-tabs content-class="mt-3">
          <b-tab title="Cadastro Receita" active>
            <h4 style="margin-top: 10px">Cadastro Receita</h4>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="empresa.cnpj">CNPJ</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.cnpj"
                    v-mask="'##.###.###/####-##'"
                  />
                </div>

                <div class="form-group">
                  <label for="razaoSocial">Razão Social</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.nome"
                  />
                </div>

                <div class="form-group">
                  <label for="nomeFantasia">Nome Fantasia</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.fantasia"
                  />
                </div>

                <div class="form-group">
                  <label for="abertura">Abertura</label>
                  <b-form-datepicker
                    v-model="empresa.abertura"
                    :class="'form-control'"
                    placeholder=""
                  ></b-form-datepicker>
                </div>

                <div class="form-group">
                  <label for="situacao">Situação</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.situacao"
                  />
                </div>

                <div class="form-group">
                  <label for="tipo">Tipo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.tipo"
                  />
                </div>

                <div class="form-group">
                  <label for="porte">Porte</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.porte"
                  />
                </div>

                <div class="form-group">
                  <label for="naturezaJuridica">Natureza Jurídica</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.natureza_juridica"
                  />
                </div>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-5">
                <h4>Atividade Principal</h4>
                <table
                  class="table table-bordered"
                  v-if="
                    empresa.atividadePrincipal &&
                    empresa.atividadePrincipal.length > 0
                  "
                >
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Descrição</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(atividade, index) in empresa.atividadePrincipal"
                      :key="index"
                    >
                      <td>{{ atividade.code }}</td>
                      <td>{{ atividade.text }}</td>
                      <td>
                        <button
                          class="btn btn-danger"
                          @click="removerAtividadePrincipal(index)"
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button
                  class="btn btn-primary"
                  @click="mostrarModalAtividadePrincipal"
                >
                  Adicionar Atividade Principal
                </button>

                <b-modal
                  id="modalAtividadePrincipal"
                  title="Adicionar Atividade Principal"
                  @ok="adicionarAtividadePrincipal"
                >
                  <div>
                    <div class="form-group">
                      <label for="codeAtividadePrincipal"
                        >Código Atividade Principal</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaAtividadePrincipal.code"
                      />
                    </div>
                    <div class="form-group">
                      <label for="textAtividadePrincipal">Descrição</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaAtividadePrincipal.text"
                      />
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </b-tab>

          <b-tab title="Endereço">
            <h4>Endereço</h4>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="logradouro">Logradouro</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.endereco.logradouro"
                  />
                </div>

                <div class="form-group">
                  <label for="numero">Número</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.endereco.numero"
                  />
                </div>

                <div class="form-group">
                  <label for="bairro">Bairro</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.endereco.bairro"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="cidade">Cidade</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.endereco.municipio"
                  />
                </div>

                <div class="form-group">
                  <label for="estado">Estado</label>
                  <select class="form-control" v-model="empresa.endereco.uf">
                    <option
                      v-for="estado in estados"
                      :key="estado.sigla"
                      :value="estado.sigla"
                    >
                      {{ estado.sigla }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="cep">CEP</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.endereco.cep"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Quadro Societário">
            <h4>Quadro Societário</h4>
            <table
              class="table table-bordered"
              v-if="empresa.qsa && empresa.qsa.length > 0"
            >
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Qualificação</th>
                  <th>Nome Representante Legal</th>
                  <th>Qualificação Representante Legal</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(qsaItem, index) in empresa.qsa" :key="index">
                  <td>{{ qsaItem.nome }}</td>
                  <td>{{ qsaItem.qual }}</td>
                  <td>{{ qsaItem.nome_rep_legal }}</td>
                  <td>{{ qsaItem.qual_rep_legal }}</td>
                  <td>
                    <button class="btn btn-danger" @click="removerQSA(index)">
                      Remover
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-primary"
              @click="mostrarModalQuadroSocietario"
            >
              Adicionar Quadro Societário
            </button>

            <b-modal
              id="modalQuadroSocietario"
              title="Adicionar Quadro Societário"
              @ok="adicionarQSA"
            >
              <div>
                <div class="form-group">
                  <label for="nomeQSA">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="novoQSA.nome"
                  />
                </div>
                <div class="form-group">
                  <label for="qualQSA">Qualificação</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="novoQSA.qual"
                  />
                </div>
                <div class="form-group">
                  <label for="nomeRepLegalQSA">Nome Representante Legal</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="novoQSA.nome_rep_legal"
                  />
                </div>
                <div class="form-group">
                  <label for="qualRepLegalQSA"
                    >Qualificação Representante Legal</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="novoQSA.qual_rep_legal"
                  />
                </div>
              </div>
            </b-modal>
          </b-tab>

          <b-tab title="Contato">
            <h4>Contato</h4>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.contato.email"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="telefone">Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.contato.telefone"
                    v-mask="'+55 (##) #####-####'"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Outras Informações">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="dataSituacao">Data Situação</label>
                  <b-form-datepicker
                    v-model="empresa.data_situacao"
                    :class="'form-control'"
                    placeholder=""
                  ></b-form-datepicker>
                </div>

                <div class="form-group">
                  <label for="ultimaAtualizacao">Última Atualização</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.ultima_atualizacao"
                    v-mask="'##/##/#### ##:##:##'"
                  />
                </div>

                <div class="form-group">
                  <label for="status">Status</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.status"
                  />
                </div>

                <div class="form-group">
                  <label for="complemento">Complemento</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.complemento"
                  />
                </div>

                <div class="form-group">
                  <label for="efr">EFR</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.efr"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="motivoSituacao">Motivo Situação</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.motivo_situacao"
                  />
                </div>

                <div class="form-group">
                  <label for="situacaoEspecial">Situação Especial</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.situacao_especial"
                  />
                </div>

                <div class="form-group">
                  <label for="dataSituacaoEspecial"
                    >Data Situação Especial</label
                  >
                  <b-form-datepicker
                    v-model="empresa.data_situacao_especial"
                    :class="'form-control'"
                    placeholder=""
                  ></b-form-datepicker>
                </div>

                <div class="form-group">
                  <label for="capitalSocial">Capital Social</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="empresa.capital_social"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Atividades Secundárias">
            <h4>Atividades Secundárias</h4>
            <table
              class="table table-bordered"
              v-if="
                empresa.atividadeSecundaria &&
                empresa.atividadeSecundaria.length > 0
              "
            >
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Texto</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(atividade, index) in empresa.atividadeSecundaria"
                  :key="index"
                >
                  <td>{{ atividade.codigo }}</td>
                  <td>{{ atividade.texto }}</td>
                  <td>
                    <button
                      class="btn btn-danger"
                      @click="removerAtividade(index)"
                    >
                      Remover
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-primary"
              @click="mostrarModalAtividadeSecundaria"
            >
              Adicionar Atividade Secundária
            </button>

            <b-modal
              id="modalAtividadeSecundaria"
              title="Adicionar Atividade Secundária"
              @ok="adicionarAtividadeSecundaria"
            >
              <div>
                <div class="form-group">
                  <label for="codigoAtivSecundaria">Código</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="novaAtividadeSecundaria.codigo"
                  />
                </div>
                <div class="form-group">
                  <label for="textoAtivSecundaria">Texto</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="novaAtividadeSecundaria.texto"
                  />
                </div>
              </div>
            </b-modal>
          </b-tab>
        </b-tabs>
      </b-card>

      <!-- Botões -->
      <div class="btn-group">
        <button
          class="btn btn-success"
          @click="salvarEmpresa"
          v-if="!modoEditar"
        >
          Salvar
        </button>
        <button
          class="btn btn-primary"
          @click="atualizarEmpresa"
          v-if="modoEditar"
        >
          Atualizar
        </button>
        <button
          class="btn btn-danger"
          @click="removerEmpresa"
          v-if="modoEditar"
        >
          Remover
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import { format } from 'date-fns';
import PageHeader from '@/components/page-header';
import { findByCNPJ } from './listEmpresas.service';
import { salvar } from './listEmpresas.service';
import { update } from './listEmpresas.service';
import { remove } from './listEmpresas.service';

Vue.use(VueMask);

export default {
  data() {
    return {
      estados: [
        { sigla: 'AC' },
        { sigla: 'AL' },
        { sigla: 'AM' },
        { sigla: 'AP' },
        { sigla: 'BA' },
        { sigla: 'CE' },
        { sigla: 'DF' },
        { sigla: 'ES' },
        { sigla: 'GO' },
        { sigla: 'MA' },
        { sigla: 'MG' },
        { sigla: 'MS' },
        { sigla: 'MT' },
        { sigla: 'PA' },
        { sigla: 'PB' },
        { sigla: 'PE' },
        { sigla: 'PI' },
        { sigla: 'PR' },
        { sigla: 'RJ' },
        { sigla: 'RN' },
        { sigla: 'RO' },
        { sigla: 'RR' },
        { sigla: 'RS' },
        { sigla: 'SC' },
        { sigla: 'SE' },
        { sigla: 'SP' },
        { sigla: 'TO' }
      ],
      novaAtividadePrincipal: {
        code: '',
        text: ''
      },
      novaAtividadeSecundaria: {
        codigo: '',
        texto: ''
      },
      novoQSA: {
        nome: '',
        qual: '',
        nome_rep_legal: '',
        qual_rep_legal: ''
      },
      cnpj: '',
      empresa: {
        abertura: '',
        situacao: '',
        tipo: '',
        nome: '',
        porte: '',
        natureza_juridica: '',
        atividade_principal: [],
        qsa: [],
        endereco: {
          logradouro: '',
          numero: '',
          municipio: '',
          bairro: '',
          uf: '',
          cep: ''
        },
        contato: {
          email: '',
          telefone: ''
        },
        data_situacao: '',
        cnpj: '',
        ultima_atualizacao: '',
        status: '',
        fantasia: '',
        complemento: '',
        efr: '',
        motivo_situacao: '',
        situacao_especial: '',
        data_situacao_especial: '',
        atividades_secundarias: [],
        capital_social: '',
        extra: {},
        billing: {
          free: true,
          database: true
        }
      },
      modoEditar: false,
      erroHttp: false,
      items: [
        {
          text: 'Backoffice',
          href: '#'
        },
        {
          text: 'Empresas',
          active: true
        }
      ]
    };
  },
  methods: {
    formatDate(dateString) {
      return dateString ? new Date(dateString) : null;
    },
    mostrarModalAtividadePrincipal() {
      this.$bvModal.show('modalAtividadePrincipal');
    },
    mostrarModalAtividadeSecundaria() {
      this.$bvModal.show('modalAtividadeSecundaria');
    },
    mostrarModalQuadroSocietario() {
      this.$bvModal.show('modalQuadroSocietario');
    },
    adicionarAtividadeSecundaria() {
      const novaAtividade = {
        codigo: this.novaAtividadeSecundaria.codigo,
        texto: this.novaAtividadeSecundaria.texto
      };
      this.empresa.atividades_secundarias.push(novaAtividade);

      // Limpar os campos após a adição
      this.novaAtividadeSecundaria.codigo = '';
      this.novaAtividadeSecundaria.texto = '';

      // Fechar o modal
      this.$bvModal.hide('modalAtividadeSecundaria');
    },
    removerAtividade(index) {
      this.empresa.atividades_secundarias.splice(index, 1);
    },

    adicionarAtividadePrincipal() {
      const novaAtividade = {
        code: this.novaAtividadePrincipal.code,
        text: this.novaAtividadePrincipal.text
      };
      this.empresa.atividade_principal.push(novaAtividade);

      // Limpar os campos após a adição
      this.novaAtividadePrincipal.code = '';
      this.novaAtividadePrincipal.text = '';

      // Fechar o modal
      this.$bvModal.hide('modalAtividadePrincipal');
    },

    removerAtividadePrincipal(index) {
      this.empresa.atividade_principal.splice(index, 1);
    },

    adicionarQSA() {
      const novoQuadro = {
        nome: this.novoQSA.nome,
        qual: this.novoQSA.qual,
        nome_rep_legal: this.novoQSA.nome_rep_legal,
        qual_rep_legal: this.novoQSA.qual_rep_legal
      };
      this.empresa.qsa.push(novoQuadro);

      // Limpar os campos após a adição
      this.novoQSA.nome = '';
      this.novoQSA.qual = '';
      this.novoQSA.nome_rep_legal = '';
      this.novoQSA.qual_rep_legal = '';

      // Fechar o modal
      this.$bvModal.hide('modalQSA');
    },

    removerQSA(index) {
      this.empresa.qsa.splice(index, 1);
    },

    buscarempresas() {
      // Simulando uma chamada assíncrona para obter os dados
      // Convertendo o mock para uma lista (se necessário)
      //console.log(this.cnpj);
      findByCNPJ(this.cnpj).then((empresas) => {
        //console.log(empresas);
        this.empresa = empresas.content;
        this.preencherCamposComEmpresa();

        //console.log(this.empresa);
        if (empresas.status === false) {
          this.erroHttp = true;
        }
      });
      // Simulando um pequeno atraso para fins ilustrativos
    },
    preencherCamposComEmpresa() {
      this.empresa.abertura = this.formatDate(this.empresa.abertura);
      this.empresa.data_situacao = this.formatDate(this.empresa.data_situacao);
      this.empresa.data_situacao_especial = this.formatDate(
        this.empresa.data_situacao_especial
      );

      if (this.empresa.ultima_atualizacao) {
        this.empresa.ultima_atualizacao = format(
          new Date(this.empresa.ultima_atualizacao),
          'dd/MM/yyyy HH:mm:ss'
        );
      }
    },
    salvarEmpresa() {
      salvar(this.empresa);
      //console.log('Salvando empresa:', this.empresa);
      this.$router.push({
        path: '/list-empresas'
      });
    },
    atualizarEmpresa() {
      update(this.empresa);
      //console.log('Atualizando empresa:', this.empresa);
      this.$router.push({
        path: '/list-empresas'
      });
    },
    removerEmpresa() {
      remove(this.empresa);
      //console.log('Removendo empresa:', this.empresa);
      this.$router.push({
        path: '/list-empresas'
      });
    }
  },
  created() {
    const data = this.$route.query.data;
    //console.log(data);
    if (data != null) {
      try {
        this.empresa = JSON.parse(data);

        this.empresa.abertura = this.formatDate(this.empresa.abertura);
        this.empresa.data_situacao = this.formatDate(
          this.empresa.data_situacao
        );
        this.empresa.data_situacao_especial = this.formatDate(
          this.empresa.data_situacao_especial
        );
      } catch (error) {
        console.error(error);
      }
    }

    if (this.empresa.id != null) {
      this.modoEditar = true;
    }

    if (this.empresa.ultima_atualizacao) {
      this.empresa.ultima_atualizacao = format(
        new Date(this.empresa.ultima_atualizacao),
        'dd/MM/yyyy HH:mm:ss'
      );
    }
  },
  computed: {
    breadcrumb: function () {
      return [
        {
          text: 'Backoffice',
          href: '/'
        },
        {
          text: 'Empresas',
          href: '/'
        },
        {
          text: this.pageTitle,
          active: true
        }
      ];
    }
  },
  components: {
    PageHeader
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Cadastro de Empresas'
    }
  }
};
</script>
<style>
.form-control.search-input {
  width: 35%;
  margin-right: 5px;
}

.form-group {
  padding: 10px 10px 0px 0px;
  width: 100%;
}
</style>
