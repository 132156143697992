import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listAll = async () => {
  //return mockResponse;
  var axios = require('axios');

  //const { token } = getAuth();
  const auth = getAuth();
  var config = {
    method: 'get',
    url: `${CONFIG.API_URL}/empresa/findAll`,
    headers: {
      Bearer: auth.token
    }
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const findByCNPJ = async (cnpj) => {
  var axios = require('axios');
  var cnpjFormatado = cnpj.replace(/\D/g, '');
  var data = { cnpj: cnpjFormatado };

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/empresa/buscaEmpresaWebService`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      Vue.$toast.success('Dados encontrados.', response.content);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const salvar = async (empresa) => {
  //return mockResponse;
  console.log(empresa);
  empresa.cnpj = empresa.cnpj.replace(/\D/g, '');

  empresa = fomatarDatas(empresa);

  var axios = require('axios');
  var data = { empresa };

  console.log(data);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/empresa/cadastraEmpresa`,
    headers: {
      Bearer: auth.token
    },
    data: data.empresa
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      Vue.$toast.success('Dados cadastrados com sucesso.', response.content);
      return response.message;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error('Sua tentativa de cadastrar a empresa falhou.');
    });
};

function fomatarDatas(empresa) {
  const abertura = new Date(empresa.abertura);

  // Extrair a parte da data (ano, mês, dia) do objeto Date
  const yearAbertura = abertura.getUTCFullYear();
  const monthAbertura = abertura.getUTCMonth() + 1; // Os meses em JavaScript são indexados a partir de 0
  const dayAbertura = abertura.getUTCDate();

  // Formatar a string no formato "java.time.LocalDate" (yyyy-MM-dd)
  empresa.abertura = `${yearAbertura}-${monthAbertura
    .toString()
    .padStart(2, '0')}-${dayAbertura.toString().padStart(2, '0')}`;

  const dataSituacao = new Date(empresa.data_situacao);

  // Extrair a parte da data (ano, mês, dia) do objeto Date
  const yeardataSituacao = dataSituacao.getUTCFullYear();
  const monthdataSituacao = dataSituacao.getUTCMonth() + 1; // Os meses em JavaScript são indexados a partir de 0
  const daydataSituacao = dataSituacao.getUTCDate();

  // Formatar a string no formato "java.time.LocalDate" (yyyy-MM-dd)
  empresa.data_situacao = `${yeardataSituacao}-${monthdataSituacao
    .toString()
    .padStart(2, '0')}-${daydataSituacao.toString().padStart(2, '0')}`;

  const ultima_atualizacao = new Date(empresa.ultima_atualizacao);

  // Extrair as partes da data (ano, mês, dia, hora, minuto) do objeto Date
  const yearUltimaSituacao = ultima_atualizacao.getUTCFullYear();
  const monthUltimaSituacao = ultima_atualizacao.getUTCMonth() + 1; // Os meses em JavaScript são indexados a partir de 0
  const dayUltimaSituacao = ultima_atualizacao.getUTCDate();
  const hourUltimaSituacao = ultima_atualizacao.getUTCHours();
  const minuteUltimaSituacao = ultima_atualizacao.getUTCMinutes();

  // Formatar a string no formato desejado (yyyy-MM-dd HH:mm)
  empresa.ultima_atualizacao = `${yearUltimaSituacao}-${monthUltimaSituacao
    .toString()
    .padStart(2, '0')}-${dayUltimaSituacao
    .toString()
    .padStart(2, '0')} ${hourUltimaSituacao
    .toString()
    .padStart(2, '0')}:${minuteUltimaSituacao.toString().padStart(2, '0')}`;

  return empresa;
}

export const update = async (empresa) => {
  //return mockResponse;
  console.log(empresa);
  empresa.cnpj = empresa.cnpj.replace(/\D/g, '');

  empresa = fomatarDatas(empresa);

  var axios = require('axios');
  var data = { empresa };

  console.log(data);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/empresa/atualizarEmpresa`,
    headers: {
      Bearer: auth.token
    },
    data: data.empresa
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      Vue.$toast.success('Dados atualizados com sucesso.', response.content);
      return response.message;
    })
    .catch(function (error) {
      Vue.$toast.error(
        'Sua tentativa de atualizar os dados da empresa falhou.'
      );
      console.log(error);
    });
};

export const remove = async (empresa) => {
  //return mockResponse;
  console.log(empresa);
  empresa.cnpj = empresa.cnpj.replace(/\D/g, '');

  var axios = require('axios');
  var data = { empresa };

  console.log(data);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/empresa/remover`,
    headers: {
      Bearer: auth.token
    },
    data: data.empresa
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      Vue.$toast.success('Dados Cadastrados.', response.content);
      return response.message;
    })
    .catch(function (error) {
      Vue.$toast.error('Sua tentativa de remover a empresa falhou.');
      console.log(error);
    });
};
